<template>
    <section class="position-relative pt-3">
        <cargando v-if="cargando" />
        <div class="row mx-0">
            <div v-if="dataTable.length" class="col-12">
                <tabla-general :data="dataTable" alto="calc(100vh - 270px)">
                    <template slot="cabecera-izquierda">
                        <p class="col-auto text-general f-18 f-600">
                            Lista de {{ $config.cliente + 's' }}
                        </p>
                    </template>
                    <template slot="adicionales-derecha">
                        <template>
                            <el-table-column prop="nombre" :label="$config.cliente" class-name="f-16 text-general text-center" width="200">
                                <template slot-scope="{ row }">
                                    <div class="cr-pointer" @click="verCliente(row)">
                                        {{ row.nombre }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="telefono" label="Telefono" class-name="f-16 text-general text-center" min-width="130" />
                            <el-table-column label="Tipo" class-name="f-16 text-general text-center" width="120">
                                <template slot-scope="{ row }">
                                    {{ _.defaultTo(row.tipo_nombre, ' - ') }}
                                </template>
                            </el-table-column>
                            <el-table-column prop="count_pedidos" label="Pedidos" class-name="f-16 text-general text-center" width="100" />
                            <el-table-column prop="tienda_nombre" :label="$config.vendedor" class-name="f-16 text-general text-center" width="200" />
                            <el-table-column prop="cedis_nombre" label="Cedis" class-name="f-16 text-general text-center" width="200" />
                            <el-table-column
                            label="Primera compra"
                            class-name="f-16 text-general text-center"
                            width="200"
                            >
                                <template slot-scope="{ row }">
                                    <p>
                                        {{ row.primera_compra | helper-fecha('D MMM YYYY') }}
                                    </p>
                                </template>
                            </el-table-column>
                            <el-table-column
                            label="Compras"
                            class-name="f-16 text-general text-center"
                            width="100"
                            align="center"
                            >
                                <template slot-scope="{ row }">
                                    {{ separadorNumero(row.sum_compra) }}
                                </template>
                            </el-table-column>
                            <el-table-column
                            label="Última compra"
                            class-name="f-16 text-general text-center"
                            width="200"
                            >
                                <template slot-scope="{ row }">
                                    <p>
                                        {{ row.ultima_compra | helper-fecha('D MMM YYYY') }}
                                    </p>
                                </template>
                            </el-table-column>
                            <el-table-column
                            label="Calificación"
                            class-name="f-16 text-general text-center"
                            width="120"
                            >
                                <template slot-scope="{ row }">
                                    <div v-if="row.calificacion" class="d-flex justify-center">
                                        <p>
                                            {{ row.calificacion }}
                                        </p>
                                        <i class="icon-star" style="color:#FF9D32;" />
                                    </div>
                                </template>
                            </el-table-column>
                            <!-- <el-table-column
                            label="Grupo"
                            class-name="f-16 text-general text-center"
                            width="180"
                            >
                                <template slot-scope="{ row }">
                                    <div class="d-flex justify-center">
                                        <template v-if="row.id_grupo">
                                            <el-select v-model="row.grupo" size="small" placeholder="Seleccionar" :clearable="true" @clear="eliminarGrupo(row.id)">
                                                <el-option
                                                :label="row.grupo"
                                                :value="row.grupo"
                                                />
                                            </el-select>
                                        </template>
                                        <template v-else>
                                            <el-select v-model="row.id_grupo" size="small" placeholder="Seleccionar" @change="actualizarGrupo(row.id,row.id_grupo,row.nombre)">
                                                <el-option
                                                v-for="grupo in grupos" :key="grupo.id"
                                                :label="grupo.nombre"
                                                :value="grupo.id"
                                                />
                                            </el-select>
                                        </template>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column
                            label="Referidos"
                            class-name="f-16 text-general text-center"
                            width="280"
                            >
                                <template slot-scope="{ row }">
                                    <div class="d-flex justify-center">
                                        <template v-if="row.id_referido">
                                            <el-select v-model="row.referido" size="small" :clearable="true" placeholder="Seleccionar" @clear="eliminarReferido(row.id)">
                                                <el-option
                                                :label="row.referido"
                                                :value="row.referido"
                                                />
                                            </el-select>
                                        </template>
                                        <template v-else>
                                            <el-select
                                            v-model="row.id_referido"
                                            remote
                                            :remote-method="buscarReferido"
                                            size="small"
                                            placeholder="Seleccionar"
                                            filterable
                                            @change="actualizarReferido(row)"
                                            >
                                                <el-option
                                                v-for="item in referidos" :key="item.id"
                                                :label="item.nombre_completo"
                                                :value="item.id_user"
                                                />
                                            </el-select>
                                        </template>
                                    </div>
                                </template>
                            </el-table-column> -->
                            <el-table-column width="120">
                                <template slot-scope="scope">
                                    <div class="row mx-0">
                                        <el-tooltip class="item" effect="light" content="Editar" placement="bottom">
                                            <div v-if="$can('boton_cliente_editar_datos')" class="d-middle-center btn-edit br-10" style="width:44px;height:44px;" @click="editar(scope.row)">
                                                <i class="icon-pencil-outline f-20" />
                                            </div>
                                        </el-tooltip>
                                    </div>
                                </template>
                            </el-table-column>
                        </template>
                    </template>
                </tabla-general>
                <!-- Sin Datos -->
            </div>
            <div v-else class="col-12">
                <sin-datos icon="list" mensaje="No hay datos para mostrar." />
            </div>
        </div>
        <!-- Modales -->
        <modal-editar-cliente ref="editar_cliente" @listar="montar()" />
        <!-- Modales -->
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
import Clientes from "../../services/clientes";
import moment from 'moment'
export default {
    components: {
        modalEditarCliente: () => import('./partials/modalEditarCliente')
    },
    data(){
        return{
            cargando: false,
            dataTable: [],
            grupos: [],
            referidos: [],
        }
    },
    computed: {
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
        }),
    },
    watch: {
        id_moneda(val){
            if(val == null) return
            this.montar()
        },
        id_cedis(){
            this.montar()
        }
    },
    mounted(){
        this.montar()
    },
    methods: {
        editar(cliente){
            this.$refs.editar_cliente.toggle(cliente)
        },
        async montar(){
            if(this.id_cedis === null || this.id_moneda === null){
                setTimeout(async() => {
                    await this.tabla_cliente()
                }, 1000);
            }else{
                await this.tabla_cliente()
            }
        },
        verCliente({id: id_cliente}){
            this.$router.push({ name: 'admin.clientes.ver.pedidos-realizados', params: { id_cliente } })
        },
        async tabla_cliente(){
            this.cargando = true;
            try {
                let params = {
                    id_moneda: this.id_moneda,
                    id_cedis: this.id_cedis
                }
                const { data } = await Clientes.tabla_cliente(params)
                this.dataTable = data.clientes
                this.grupos = data.grupos
                this.cargando = false;
            } catch (error){
                this.cargando = false;
                this.error_catch(error)
            }
        },
        async actualizarGrupo(idUser,idGrupo,cliente){
            this.cargando = true;
            try {
                let form = {
                    id_grupo: idGrupo
                }
                const { data } = await Clientes.actualizarGrupo(idUser,form)
                let index = this.dataTable.findIndex(e => e.id === idUser)
                this.dataTable[index].id_grupo = idGrupo;
                this.dataTable[index].grupo = data.grupo;
                this.notificacion('Mensaje', 'El grupo seleccionado se ha actualizado correctamente', data.tipo)
                this.cargando = false;
            } catch (error){
                this.cargando = false;
                this.error_catch(error)
            }
        },
        async actualizarReferido(row){
            this.cargando = true;
            try {
                let form = {
                    id_referido: row.id_referido
                }
                const { data } = await Clientes.actualizarReferido(row.id,form)
                let index = this.dataTable.findIndex(e => e.id === row.id)
                this.dataTable[index].id_referido = row.id_referido;
                this.dataTable[index].referido = data.referido;
                this.referidos = [];
                this.notificacion('Mensaje', 'El referido del cliente seleccionado se ha actualizado correctamente', data.tipo)
                this.cargando = false;
            } catch (error){
                this.cargando = false;
                this.error_catch(error)
            }
        },
        async buscarReferido(query){
            try {
                if(query === '' || query === null)return
                let params = {
                    buscador: query
                }
                const { data } = await Clientes.buscarReferidos(params)
                if (query != '' && query != null){
                    this.loading = true;
                    setTimeout(() => {
                        this.loading = false;
                        this.referidos = data.referidos.filter(item => {
                            return item.nombre_completo.toLowerCase()
                                .indexOf(query.toLowerCase()) > -1;
                        });
                    }, 200);
                }
            } catch (error){
                this.error_catch(error)
            }
        },
        async eliminarReferido(idUser){
            this.cargando = true;
            try {
                const { data } = await Clientes.eliminarReferido(idUser)
                let index = this.dataTable.findIndex(e => e.id === idUser)
                this.dataTable[index].id_referido = "";
                this.dataTable[index].referido = "";
                this.cargando = false;
            } catch (error){
                this.cargando = false;
                this.error_catch(error)
            }
        },
        async eliminarGrupo(idUser){
            this.cargando = true;
            try {
                const { data } = await Clientes.eliminarGrupo(idUser)
                let index = this.dataTable.findIndex(e => e.id === idUser)
                this.dataTable[index].id_grupo = "";
                this.dataTable[index].grupo = "";
                this.cargando = false;
            } catch (error){
                this.cargando = false;
                this.error_catch(error)
            }
        },
        verReferido(id_referido){
            this.$router.push({ name: 'admin.clientes.ver.pedidos-realizados', params: { id_cliente: id_referido } })
        },
    }
}
</script>

<style lang="scss">
.br-12{
    border-radius: 12px;
}
</style>
