var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"position-relative pt-3"},[(_vm.cargando)?_c('cargando'):_vm._e(),_c('div',{staticClass:"row mx-0"},[(_vm.dataTable.length)?_c('div',{staticClass:"col-12"},[_c('tabla-general',{attrs:{"data":_vm.dataTable,"alto":"calc(100vh - 270px)"}},[_c('template',{slot:"cabecera-izquierda"},[_c('p',{staticClass:"col-auto text-general f-18 f-600"},[_vm._v(" Lista de "+_vm._s(_vm.$config.cliente + 's')+" ")])]),_c('template',{slot:"adicionales-derecha"},[[_c('el-table-column',{attrs:{"prop":"nombre","label":_vm.$config.cliente,"class-name":"f-16 text-general text-center","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"cr-pointer",on:{"click":function($event){return _vm.verCliente(row)}}},[_vm._v(" "+_vm._s(row.nombre)+" ")])]}}],null,false,2874690047)}),_c('el-table-column',{attrs:{"prop":"telefono","label":"Telefono","class-name":"f-16 text-general text-center","min-width":"130"}}),_c('el-table-column',{attrs:{"label":"Tipo","class-name":"f-16 text-general text-center","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._.defaultTo(row.tipo_nombre, ' - '))+" ")]}}],null,false,178096794)}),_c('el-table-column',{attrs:{"prop":"count_pedidos","label":"Pedidos","class-name":"f-16 text-general text-center","width":"100"}}),_c('el-table-column',{attrs:{"prop":"tienda_nombre","label":_vm.$config.vendedor,"class-name":"f-16 text-general text-center","width":"200"}}),_c('el-table-column',{attrs:{"prop":"cedis_nombre","label":"Cedis","class-name":"f-16 text-general text-center","width":"200"}}),_c('el-table-column',{attrs:{"label":"Primera compra","class-name":"f-16 text-general text-center","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',[_vm._v(" "+_vm._s(_vm._f("helper-fecha")(row.primera_compra,'D MMM YYYY'))+" ")])]}}],null,false,4075790635)}),_c('el-table-column',{attrs:{"label":"Compras","class-name":"f-16 text-general text-center","width":"100","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.separadorNumero(row.sum_compra))+" ")]}}],null,false,2483168075)}),_c('el-table-column',{attrs:{"label":"Última compra","class-name":"f-16 text-general text-center","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',[_vm._v(" "+_vm._s(_vm._f("helper-fecha")(row.ultima_compra,'D MMM YYYY'))+" ")])]}}],null,false,878529651)}),_c('el-table-column',{attrs:{"label":"Calificación","class-name":"f-16 text-general text-center","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.calificacion)?_c('div',{staticClass:"d-flex justify-center"},[_c('p',[_vm._v(" "+_vm._s(row.calificacion)+" ")]),_c('i',{staticClass:"icon-star",staticStyle:{"color":"#FF9D32"}})]):_vm._e()]}}],null,false,4030290036)}),_c('el-table-column',{attrs:{"width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"row mx-0"},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"light","content":"Editar","placement":"bottom"}},[(_vm.$can('boton_cliente_editar_datos'))?_c('div',{staticClass:"d-middle-center btn-edit br-10",staticStyle:{"width":"44px","height":"44px"},on:{"click":function($event){return _vm.editar(scope.row)}}},[_c('i',{staticClass:"icon-pencil-outline f-20"})]):_vm._e()])],1)]}}],null,false,586876076)})]],2)],2)],1):_c('div',{staticClass:"col-12"},[_c('sin-datos',{attrs:{"icon":"list","mensaje":"No hay datos para mostrar."}})],1)]),_c('modal-editar-cliente',{ref:"editar_cliente",on:{"listar":function($event){return _vm.montar()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }