<template>
    <section>
        <div class="row mx-0 align-items-center mt-3 mb-3">
            <div class="col-auto px-2" />
            <i class="icon-account-group-outline f-22 text-general" />
            <div class="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4">
                <el-select
                v-model="id_grupo" size="small"
                class="w-100"
                filterable
                remote
                reserve-keyword
                placeholder="Buscar Grupo"
                :loading="loading"
                @change="seleccionarGrupo"
                >
                    <el-option
                    v-for="(grupo,x) in grupos"
                    :key="x"
                    :label="grupo.nombre"
                    :value="grupo.id"
                    />
                </el-select>
            </div>
            <el-tooltip placement="bottom" content="Editar grupos" effect="light">
                <div v-if="$can('boton_estadisticas_editar_grupo')" class="btn-action ml-2 border cr-pointer" @click="$router.push({name: 'admin.clientes.lista'})">
                    <i class="icon-pencil-outline f-22" />
                </div>
            </el-tooltip>
            <div class="col-auto text-general">
                <b class="f-27">
                    {{ clientes_grupo }}
                </b>
                <small>{{$config.cliente}}</small>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4 ml-auto">
                <el-date-picker
                v-model="rango_fechas"
                type="daterange"
                :clearable="false"
                size="small"
                range-separator="a"
                class="w-100"
                start-placeholder="Fecha Inicial"
                end-placeholder="Fecha Final"
                format="dd MMM yyyy"
                value-format="yyyy-MM-dd"
                />
            </div>
            <div v-if="$can('boton_estadisticas_mostrar_grupos')" class="btn-general px-4 f-14" @click="getListarReferidos">
                Mostrar
            </div>
        </div>
        <div class="row mx-0">
            <div class="col-12 px-0">
                <tabla-general
                :data="referidos"
                header-row-class-name="text-general f-16"
                style="width: 100%"
                class-header="text-general text-center f-16" alto="calc(100vh - 288px)"
                >
                    <template slot="cabecera-izquierda">
                        <p class="col-auto text-general f-18 f-400">
                            {{$config.cliente}} del grupo
                        </p>
                    </template>
                    <el-table-column
                    label="Cliente"
                    class-name="text-center"
                    >
                        <template slot-scope="scope">
                            <p class="cr-pointer" @click="referidoClientes(scope.row.id,scope.row.cliente,scope.row.foto)">
                                {{ scope.row.cliente }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="total_pedidos_cliente"
                    :label="`Pedidos del ${$config.cliente}`"
                    class-name="text-center"
                    />
                    <el-table-column
                    label="Valor"
                    class-name="text-center"
                    width="210"
                    >
                        <template slot-scope="scope">
                            <p>
                                {{ separadorNumero(scope.row.total_pedidos_cliente_valor) }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="total_referidos"
                    class-name="text-center"
                    label="Total Referidos"
                    />
                    <el-table-column
                    prop="total_referidos_pedidos"
                    class-name="text-center"
                    label="Pedidos referidos"
                    />
                    <el-table-column
                    label="Valor referidos"
                    class-name="text-center"
                    >
                        <template slot-scope="scope">
                            <p>
                                {{ separadorNumero(scope.row.total_referidos_valor) }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="referidos_fechas"
                    class-name="text-center"
                    label="Referidos en Fecha"
                    />
                </tabla-general>
            </div>
        </div>
        <!-- Partials -->
        <modal-referidos-clientes ref="referidosClientes" />
    </section>
</template>

<script>
import Referidos from "../../services/clientesEstadisticas/referidos";
import moment from 'moment'
export default {
    components: {
        modalReferidosClientes: () => import('./partials/modalReferidosClientes')
    },
    data(){
        return{
            loading: false,
            referidos: [],
            grupos: [],
            grupo_select: [],
            id_grupo: null,
            rango_fechas: [],
            rango_fechas_original: [],
            clientes_grupo: 0,
            pickerOptions: {
                disabledDate(time){
                    return time.getTime() < moment().subtract(1,'days');
                }
            }
        }
    },
    created(){
        this.getListarInfo();
    },
    methods: {
        async getListarInfo(){
            try {
                const { data } = await Referidos.getListarGrupos()
                this.grupos = data.grupos;
                this.rango_fechas = data.fechas;
                this.rango_fechas_original = data.fechas;
            } catch (error){
                this.error_catch(error)
            }
        },
        async getListarReferidos(){
            try {
                if(this.id_grupo === null || this.fecha === null){return};
                let params = {
                    id_grupo: this.id_grupo,
                    fechas: this.rango_fechas,
                }
                const { data } = await Referidos.getListarReferidos(params)
                this.referidos = data.referidos
            } catch (error){
                this.error_catch(error)
            }
        },

        async seleccionarGrupo(){
            this.grupo_select = this.grupos.filter((item) => item.id === this.id_grupo );
            this.clientes_grupo = this.grupo_select[0].clientes;
            this.rango_fechas = this.rango_fechas_original;
            this.getListarReferidos();
        },

        referidoClientes(id_cliente,cliente,foto){
            this.$refs.referidosClientes.toggle(id_cliente,cliente,foto,this.rango_fechas);
        }
    }
}
</script>
<style lang="scss" scoped>
.f-27{
    font-size: 27px !important;
}
</style>
